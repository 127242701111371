import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "Home" */ "../views/Home.vue");
    },
  },
  {
    path: "/services",
    name: "Services",
    component: function () {
      return import(/* webpackChunkName: "Devis" */ "../views/Services.vue");
    },
  },
  {
    path: "/devis/:slug?",
    name: "Devis",
    component: function () {
      return import(/* webpackChunkName: "Devis" */ "../views/Devis.vue");
    },
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    component: function () {
      return import(
        /* webpackChunkName: "Devis" */ "../views/MentionsLegales.vue"
      );
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: function () {
      return import(/* webpackChunkName: "Devis" */ "../views/NotFound.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
